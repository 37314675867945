import React from 'react';
import { extend } from '@react-three/fiber'
import { Html } from '@react-three/drei';
import { defaults3d } from './defaults';
import { Vector3, Vector2, DoubleSide } from 'three';

// import { MeshLine, MeshLineMaterial } from 'threejs-meshline'
// extend({ MeshLine, MeshLineMaterial })

export interface PersonLoc {
    id: any,
    x: number
    y: number
}

interface Props {
    person: PersonLoc
    position: [number, number, number?]
    color?: string
    hideTags?: boolean
}

export class Person3D extends React.Component<Props, {}> {
    state = {}

    render() {

        let position = new Vector3(this.props.position[0], this.props.position[1], this.props.position[2]);



        // Person body curve
        const points: Vector2[] = [];
        for (let i = 0; i < 10; ++i) {
            let scale = 0.1;

            points.push(new Vector2(
                (Math.sin(i * 0.25) * 2.5 + 1) * scale,
                ((i - 5) * 1.5) * scale
            ));
        }


        return <>


            <group position={position} scale={[2, 2, 2]}  {...defaults3d}>

                <mesh position={[0, 0, 1.4]} {...defaults3d}>
                    <octahedronGeometry attach="geometry" args={[.3, 2]} {...defaults3d} />
                    <meshBasicMaterial attach="material" color={this.props.color}
                        side={DoubleSide}
                    />


                </mesh>

                <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0.5]} {...defaults3d}>
                    <latheGeometry attach="geometry" args={[points]} {...defaults3d} />
                    <meshBasicMaterial attach="material" color={this.props.color}
                        side={DoubleSide}
                    />
                </mesh>


                {(this.props.hideTags !== true) && <Html position={[0, 0, 1.4]}>

                    <div style={{
                        width: 0,
                        height: 0,
                        position: 'absolute',
                        bottom: 20,
                        right: 0,
                        color: 'white',
                    }}>
                        <div style={{
                            textAlign: 'center',
                            width: 200,
                            bottom: 0,
                            left: -100,
                            position: 'absolute',
                            fontSize: '15px',
                            lineHeight: '15px',
                            color: 'white',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div style={{ flex: 1 }} />
                            <div style={{
                                whiteSpace: 'nowrap',
                                flex: 0,
                                background: 'rgba(0,0,0,0.5)',
                                padding: 4,
                                borderRadius: 4
                            }}>{this.props.person.id}<br />
                            x: {this.props.person.x},
                            y: {this.props.person.y}
                            </div>
                            <div style={{ flex: 1 }} />
                        </div>



                    </div>
                </Html>}

            </group>
        </>
    }
}