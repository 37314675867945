import { EventEmitter } from "events";

export const sceneData = {

    scene: []
}


// const WebSocket = require('isomorphic-ws')

class API extends EventEmitter {
    ws: WebSocket;
    refs: any = {}

    state = {
        scene: [{
            name: 'Vodafone',
            type: 'collection',
            displayNested: true,
            nested: []
        }]
    }

    constructor() {
        super();
        this.ws = new WebSocket(`${window.location.origin.replace("http", "ws")}/websocket`);

        // 

        this.ws.onopen = () => {
            // this.ws.send(JSON.stringify({ userconnected: 'hello' }))
        }

        this.ws.onmessage = (event) => {
            // console.log('message recv', event.data)
            if (isJson(event.data)) {
                let data = JSON.parse(event.data);
                if (data.type) {
                    this.emit(data.type, data.data);
                }
                // if (data.type === 'scenedata') {
                //     this.emit('scenedata', data)
                // }
            };
            // this.emit('socket', event.data)
        }

        this.on('notification', (msg) => {
            console.log('api.ts test event', msg)
            this.ws.send(JSON.stringify(msg));
        })

    }

    

    account: User = {
        naam: 'asdsad',
        surname: 'asdasd',
        age: 123,
        address: {
            code: 0,
            street: 'sadmklasd'
        }
    }


    test() {

    }

    em() {
        this.emit('editMode', true);
        
    }

    setState(data: any) {
        console.log('API setState', data);
    }
}



const apiinstance = new API()
const globalAny: any = global;
globalAny.api = apiinstance
export const api = apiinstance

interface User {
    naam: string
    surname: string
    age: number
    address: Address
}

interface Address {
    street: string
    code: number
}

export function isJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}