import React, { Component } from 'react';
import { OptionMaster } from './optionmaster'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';



export default class OptionsBoolean extends OptionMaster {
  state = { val: false }

  constructor(props: any) {
    super(props);

    let value = false // default
    if (props) {
      if (props.option) {
        if (props.option.default !== undefined) { value = props.option.default }
        if (props.option.val !== undefined) { value = this.props.option.val }
        if (props.option.value !== undefined) { value = this.props.option.value }
      }
    }

    this.state.val = value;
  }

  componentWillReceiveProps = (props: any) => {
    let value = false // default
    if (props) {
      if (props.option) {
        if (props.option.default !== undefined) { value = props.option.default }
        if (props.option.val !== undefined) { value = this.props.option.val }
        if (props.option.value !== undefined) { value = this.props.option.value }
      }
    }
    this.setState({ val: value })
  }

  onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.apply();
    }
  }

  onChange = (event: any) => {
    this.setState({ val: !this.state.val }, () => {
      this.apply();
    });
  }

  render() {
    return <FormControlLabel
      checked={this.state.val}
      onChange={this.onChange}
      control={<Checkbox />}
      label={this.props.name}
    />
  }
}