import React, { useState, useCallback } from "react";
import { Paper, Button } from "@mui/material";
import * as optionsComponentsImport from '../dashboard/options'

export function recursiveFlat(inObj: any) {
    const res: any = [];

    (function recurse(obj, current?: any) {

        if (Array.isArray(obj)) { return; }

        for (const key in obj) {
            if (key) {
                const value = obj[key];
                const newKey = current ? current + '.' + key : key; // joined key with dot
                if (value && typeof value === 'object') {
                    res.push(newKey);
                    recurse(value, newKey); // it's a nested object, so do it again
                } else {
                    res.push(newKey); // it's not an object, so set the property
                }
            }
        }
    })(inObj);

    return res;
}

export function View3DUserOptions({ options, data, propsAction, onOpen }: any) {
    const [isOpen, setIsOpen] = useState(false);
    const [lastState, setLastState] = useState('')


    const action = (optionName: string) => {
        return (data: any) => {
            const option: any = {}
            option[optionName] = data.apply;
            // this.props.action({ option })
            // console.log({ option });
            propsAction({ option });
        }
    }

    React.useEffect(() => {
        let newstate = JSON.stringify(options, null, 2)
        if (newstate != lastState) {
            setLastState(newstate);
        }
    });

    let optionsJSON = JSON.stringify(options, null, 2);

    let optionsArr: any = [];

    if (options) Object.keys(options).map((name, i) => {
        optionsArr.push({ ...options[name], ...{ name } });
    })

    if (!isOpen) return <div style={{
        position: 'absolute',
        top: 10,
        bottom: 0,
        right: 10,
        height: 31,
        zIndex: 4000,
        width: 80,
    }}><Button onClick={() => { setIsOpen(!isOpen); onOpen(); }}>Options</Button></div>
    // <div>
    //     <div style={{ display: 'flex', flexDirection: 'row' }}>
    //         <div style={{ flex: 1 }} />

    //     </div>
    // </div>

    return <div style={{
        position: 'absolute',
        top: 10,
        bottom: 0,
        right: 10,
        height: "100%",
        zIndex: 4000,
        width: 300,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex",
        flexDirection: "column"
    }}>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }} />
                <Button onClick={() => { setIsOpen(!isOpen); onOpen(); }}>close</Button>
            </div>
        </div>

        {(isOpen) && <Paper 
        elevation={10}
        sx={{
            overflow: "scroll",
            pl: 2,
            pt: 1,
        }}>
            {(isOpen) && optionsArr.map((x: any, i: any) => {
                const optionsComponents: any = optionsComponentsImport;
                const OptionToDraw = optionsComponents[x.type.toLowerCase()]

                if (!OptionToDraw) {
                    return <div style={{ padding: 20 }}>
                        Error {x.type.toLowerCase()}</div>
                }

                return <div key={i}>
                    <OptionToDraw
                        key={i}
                        name={x.name}
                        option={x}
                        action={action(x.name)}
                        datasourceoptions={recursiveFlat(data)}
                    />
                </div>
            })
            }
        </Paper>}
    </div>
}

