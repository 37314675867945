import WebSocket from 'isomorphic-ws'
require('isomorphic-fetch');

// const WebSocket = require('isomorphic-ws');
// const WebSocket: any = require('isomorphic-ws')
// import WebSocket from 'isomorphic-ws'

// import WebSocket from 'ws'

/*

Meeting room on floor 5, 6 and 7
Number of people on floor 5,6 and 7
People location inside a POC are on 6th floor1.

RX_GB_W68BJ_<floor>_<MR/EN/PD>_PPC_<id>
where <floor> is the floor where the room is (5, 6 or 7), 
<MR/EN> MR is used for meeting rooms, 
PD is used for pods (small meeting rooms), 
EN is used for floor number of people, 
<id> is the identifier of the meeting room. 

For example for room meeting room 10 on floor 6 the resource name is
IRX_GB_W68BJ_6_MR_PPC_10. 

For number of people on the 5th floor, resource name is IRX_GB_W68BJ_5_EN_PPC_01.

Type	Number	ID	Column1	
PD	1	IRX_GB_W68BJ_3_PD_PPC_01	Y	
PD	2	IRX_GB_W68BJ_3_PD_PPC_02	Y	
PD	3	IRX_GB_W68BJ_3_PD_PPC_03	Y	
PD	4	IRX_GB_W68BJ_3_PD_PPC_04	Y	
PD	6	IRX_GB_W68BJ_3_PD_PPC_06	N	
PD	7	IRX_GB_W68BJ_3_PD_PPC_07	N	
PD	8	IRX_GB_W68BJ_3_PD_PPC_08	N	
PD	15	IRX_GB_W68BJ_3_PD_PPC_15	Y	
PD	16	IRX_GB_W68BJ_3_PD_PPC_16	Y	
PD	17	IRX_GB_W68BJ_3_PD_PPC_17	Y	
PD	18	IRX_GB_W68BJ_3_PD_PPC_18	Y	
            	

*/

// wss://ws3.irlynx.com/?uuid=ykC975jgvS45VN8B
// wss://ws3.irlynx.com/?uuid=604e5cc556a62d2e
// wss://ws0.irlynx.com/?uuid=DrqDQEjUVdBwytkZ
// wss://ws0.irlynx.com/?uuid=vodafoneproto
// final wss://ws0.irlynx.com/?uuid=r26igl66kj3y9ew6
// https://ws6.irlynx.com/?uuid=r26igl66kj3y9ew6
// FINAL WS: wss://ws6.irlynx.com/?uuid=r26igl66kj3y9ew6

// floor 3 = r26igl66kj3y9ew6
// floor 6 = 604e5cc556a62d2e

export interface IRLynxWebsocketFrame {
    /**  Message index */
    I: number
    /** Timestamp UTC in milliseconds */
    T: number
    /** Data as described below */
    D: string

}

export interface IRLynxWebsocketFrameFence {
    /** ALT packet fence data */
    table?: string
    res?: string
    values: [number, number][]
}

/*
  Message structure of data is as following

              HEADER                   |                    DATA
  -------------------------------------------------------------------------------
  Opcode (1byte) | Data Length (1byte) | Serial data, size depends on Data Length

  Several messages (multiple opcodes) can be received inside the same frame.
  Opcodes are unique identifiers for each function, the following table describes
  opcodes used in this websocket.

 Opcode (hex) | Opcode (dec) | function | Size | Data
 ----------------------------------------------------

0x22           34          eventPresense       1    When a variation has been detected,
                                                    If = 0: No one detected
                                                    If = 1: Presence detected

0x23           35


*/


interface IRLynxOPCode {
    opcodeHex: number
    opcodeDec: number
    function: string
    size: number
    description: string
    properties: { name: string, length: number }[]
}




let opcodesDefinitiions: IRLynxOPCode[] = []
opcodesDefinitiions.push({
    opcodeHex: 0x22,
    opcodeDec: 34,
    function: 'eventPresense',
    size: 1,
    description: `When a variation has been detected. 0 = no one, 1 = presence detected`,
    properties: [{ name: 'presence', length: 1 }]
})

opcodesDefinitiions.push({
    opcodeHex: 0x23,
    opcodeDec: 35,
    function: 'eventPeopleNumber',
    size: 1,
    description: `When a change in people number has been detected, receives the new number of people.`,
    properties: [{ name: 'peopleNumber', length: 1 }]
})

opcodesDefinitiions.push({
    opcodeHex: 0x33,
    opcodeDec: 51,
    function: 'eventLocation',
    size: 6,
    description: `Get 6 bytes: internal id, direction, X (2 bytes) Y (2 bytes) for a person that moved on the scene.`,
    properties: [
        { name: 'id', length: 1 },
        { name: 'direction', length: 1 },
        { name: 'x', length: 2 },
        { name: 'y', length: 2 }
    ]
})
// Direction of people is not sent currently, and value is always 0x08.

opcodesDefinitiions.push({
    opcodeHex: 0x34,
    opcodeDec: 52,
    function: 'eventLeave',
    size: 2,
    description: 'When an object leaves the scene, get 2 bytes: internal id of the object, border where it leaves the scene.',
    properties: [{ name: 'id', length: 1 }, { name: 'border', length: 1 }]
})

opcodesDefinitiions.push({
    opcodeHex: 0x35,
    opcodeDec: 53,
    function: 'eventDisappear',
    size: 1,
    description: `Get the id of the object which disappeared because it didn't move for too much time`,
    properties: [{ name: 'id', length: 1 }]
})


export interface IRLynxEventLocation {
    direction: number,
    id: number,
    x: number,
    y: number
}

// frame example:
export const sample: IRLynxWebsocketFrame = {
    I: 189023,
    T: 1575630356455,
    D: "53 1 35 53 1 35 53 1 71 35 1 37 51 6 3 8 1 157 1 58 51 6 9 8 0 199 1 110"
}

/*
•53 1 35                    : Object with ID 35 disappeared (message can be repeated several times to ensure not losing information)
•53 1 71                    : Object with ID 71 disappeared
•35 1 37                    : New number of people 37
•51 6 3 8 1 157 1 58        : For object with ID 3, direction is 0x08, position is X=413 and Y=314
•51 6 9 8 0 199 1 110       : For object with ID 3, direction is 0x08, position is X=199 and Y=366
*/

export class IoT_IRLynx {
    uuid!: string;
    wsUri!: string;
    ws!: WebSocket;
    opcodes: IRLynxOPCode[];

    constructor(options?: { uuid: string }) {
        this.opcodes = opcodesDefinitiions;

        if (options) {
            if (options.uuid) {
                this.uuid = options.uuid;
                this.wsUri = 'wss://ws6.irlynx.com/?' + encodeQueryData({ uuid: this.uuid });
                this.connectWebsocket();
            }
        }

    }

    decodeFrame(frameIn: any): any[] | undefined {

        if (frameIn.table) {
            let frame: IRLynxWebsocketFrameFence = frameIn;
            let decoded = [frame];
            return decoded;
        }

        if (frameIn.D) {
            let frame: IRLynxWebsocketFrame = frameIn;

            let messageIndex = frame.I;
            let timestamp = new Date(frame.T)
            let a: any = frame.D.split(' ');
            let decodedPackets = []
            while (a.length > 0) {
                const chunkOpcode = parseInt(a.shift());
                let opFind = this.opcodes.filter(op => op.opcodeDec === chunkOpcode)
                if (opFind.length !== 1) { console.error('invalid opcode'); return; }
                const opcode = opFind[0];
                const size = parseInt(a.shift());
                // console.log(opcode.function + ' size:' + size)
                // let decoded: any = { ...opcode, ...{ timestamp } }
                let decoded: any = { timestamp, function: opcode.function }
                let chunkData: any = [];
                for (var b = 0; b < size; b++) {
                    chunkData.push(a.shift())
                }
                // console.log(chunkData)
                for (var prop of opcode.properties) {
                    // console.log(`${prop.name} length ${prop.length}`)
                    if (prop.length === 1) {
                        decoded[prop.name] = parseInt(chunkData.shift());
                    }
                    if (prop.length === 2) {
                        decoded[prop.name] = parseInt(chunkData.shift()) * 256 + parseInt(chunkData.shift());
                    }
                }
                decodedPackets.push(decoded);
            }
            return decodedPackets;

        }


    }

    // getWebsocketKey() {
    //     // this.ws = new WebSocket('wss://ws0.irlynx.com/?uuid=r26igl66kj3y9ew6', {

    //     const url = 'wss://ws0.irlynx.com/?' + encodeQueryData({ uuid: this.uuid });
    //     this.ws = new WebSocket(url, 'lws-mirror-protocol', { followRedirects: true });
    //     this.ws.onopen = () => { console.log(`connected to [${this.uuid}] at ${this.wsUri}`); }
    //     this.ws.onclose = () => { console.log('disconnected'); }
    //     this.ws.onerror = (err) => {
    //         console.log(err.target['_req']._header);
    //         console.log(err.target['_req'].res.headers);
    //     }
    // }

    // getWebsocketUrl(cb: (err: Error, wsUri: string) => void) {
    //     // const url = 'https://ws0.irlynx.com/?uuid=r26igl66kj3y9ew6'
    //     // weird redirect trick...
    //     const url = 'https://ws0.irlynx.com/?' + encodeQueryData({ uuid: this.uuid });
    //     fetch(url).then(res => {
    //         // console.log(res);
    //         // console.log(res.headers)
    //         return res.url.split('https:').join('wss:')
    //     }).then(
    //         (wsUri) => {
    //             cb(undefined, wsUri)
    //         });
    // }

    connectWebsocket() {
        const portname = '';
        const baudRate = 19200;
        // this.ws = new WebSocket('wss://ws0.irlynx.com/?uuid=r26igl66kj3y9ew6', {
        //this.ws = new WebSocket(this.wsUri, { setHost: true, followRedirects: true, headers: { 'Host': 'ws3.irlynx.com', Connection: 'keep-alive' } });
        console.log(`${new Date().toISOString()} \t ${this.wsUri}`)
        this.ws = new WebSocket(this.wsUri, "lws-mirror-protocol", { followRedirects: true });
        // "lws-mirror-protocol"

        this.ws.onopen = () => {
            console.log(`connected to [${this.uuid}] at ${this.wsUri}`);
            // this.ws.send('open ' + portname + ' ' + baudRate);
        }

        this.ws.onclose = () => {
            console.log('disconnected');
        }

        // this.ws.onmessage = (data) => {
        //     console.log(`data recv from [${this.uuid}]`)
        //     console.log(data.data)
        //     console.log(data.data.toString())

        //     // console.log(`Roundtrip time: ${Date.now() - data} ms`);

        //     // setTimeout( () => {
        //     //     ws.send(Date.now());
        //     // }, 500);
        // }

        this.ws.onerror = (err) => {
            console.log(err.message);
        }

        // this.ws.on('upgrade', (req, res) => {
        //     console.log('upgrade');
        // })

        // this.ws.on('unexpected-response', (req, res) => {
        //     console.log('unexpected-response', req, res);
        // })

        // this.ws.on('ping', (req, res) => {
        //     console.log('ping', req, res);
        // })

        // this.ws.on('pong', (req, res) => {
        //     console.log('pong', req, res);
        // })

        // this.ws.on('message', (data) => {
        //     console.log('message', data)
        // })
    }

    getCapacity() {
        // fetch get https://restapi.irlynx.com/vodafone3/api/fence
        /*
{"fence":{"columns":["id","date","res_id","count"],"records":[[1591046437348724000,"2020-06-01 23:20:37.348724","IRX_GB_W68BJ_3_MR_PPC_CEO",0],[1591046435735573000,"2020-06-01 23:20:35.735573","IRX_GB_W68BJ_3_MR_PPC_CEO",1],[1591046393949645000,"2020-06-01 23:19:53.949645","IRX_GB_W68BJ_3_MR_PPC_19",0],[1591046393924085000,"2020-06-01 23:19:53.924085","IRX_GB_W68BJ_3_MR_PPC_19",1],[1591046391949681000,"2020-06-01 23:19:51.949681","IRX_GB_W68BJ_3_MR_PPC_19",2],[1591046391949217000,"2020-06-01 23:19:51.949217","IRX_GB_W68BJ_3_MR_PPC_19",1],[1591046375787557000,"2020-06-01 23:19:35.787557","IRX_GB_W68BJ_3_MR_PPC_22",0],[1591046372695505000,"2020-06-01 23:19:32.695505","IRX_GB_W68BJ_3_MR_PPC_22",1],[1591046229596935000,"2020-06-01 23:17:09.596935","IRX_GB_W68BJ_3_MR_PPC_23",0],[1591046220025077000,"2020-06-01 23:17:00.025077","IRX_GB_W68BJ_3_MR_PPC_23",1],[1590776999750139000,"2020-05-29 20:29:59.750139","IRX_GB_W68BJ_3_MR_PPC_19",0],[1590776999385697000,"2020-05-29 20:29:59.385697","IRX_GB_W68BJ_3_PD_PPC_03",0],[1590775201625867000,"2020-05-29 20:00:01.625867","IRX_GB_W68BJ_3_MR_PPC_19",1],[1590773400000152000,"2020-05-29 
        */
    }


    disconnect() {
        this.ws.removeAllListeners();
        this.ws.close();
    }

}


function encodeQueryData(data: any) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

