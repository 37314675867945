import { useThree, useFrame } from "@react-three/fiber";
import { useState, useRef } from "react";
import { Vector3 } from "three";
import { OrbitControls } from '@react-three/drei';
import React from "react";
import { api } from "../../api";

export const CameraControls = (props: any) => {
    // Get a reference to the Three.js Camera, and the canvas Html element.
    // We need these to setup the OrbitControls class.
    // https://threejs.org/docs/#examples/en/controls/OrbitControls

    const {
        camera,
        gl: { domElement }
    } = useThree();



    const [initialized, setInitialized] = useState(false);

    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls: any = useRef();
    api.refs.controls = controls;
    // controls.current.target = new Vector3(-31, 1, 42);

    useFrame(state => {
        if (initialized === false) {
            console.log('set is false...')
            if (controls === undefined) { return; }
            if (controls.current !== undefined) {
                api.refs.camera = camera;
                camera.up.set(0, 0, 1)
                controls.current.up = new Vector3(0, 0, 1);

                controls.current.target = props.target;
                // controls.current.object.setPosition(new Vector3(-92, 48, 33))
                setInitialized(true);
            }
        }
        controls.current.update();
        // console.log(controls);
    });



    return (
        <OrbitControls
            dampingFactor={0.1}
            ref={controls}
            args={[camera, domElement]}
            enableZoom={true}
        // maxAzimuthAngle={Math.PI / 4}  // dont use
        // minAzimuthAngle={-Math.PI / 4} // dont use
        // maxPolarAngle={Math.PI / 2.3} //sky
        // minPolarAngle={0.4} // floor
        />
    );
};