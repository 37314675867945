import React, { Suspense, useRef, useState } from 'react';

import { api, sceneData } from '../../../api';
import { IoT_IRLynx, IRLynxWebsocketFrame, IRLynxEventLocation } from '../../../../shared/irlynx/irlynx';
import { Model } from '../../view3d/model';
import { Html } from '@react-three/drei';
import * as THREE from 'three';
import { Color, Vector3 } from 'three';
import { View3DUserOptions } from '../../view3d/view3dUserOptions';
import { GLIRLynxPeople } from '../../view3d/glIRLynxPeople';

import { Button, Checkbox, Paper } from '@mui/material';

import { Canvas, useFrame } from '@react-three/fiber';
import { CameraControls } from '../../view3d/cameraControls';
import { Plane } from '../../view3d/types/plane';
import { clone } from '../../../../shared/utils';


export interface WidgetOption {
    type: string
    default: any,
    value?: any
}

export interface WidgetOptions {
    [index: string]: WidgetOption;
}

export interface WidgetState {
    options: WidgetOptions
    [index: string]: any
}




// {
//     // 'testA': { uuid: '604e5cc556a62d2e', x: 12, y: 304, id: 'testA' },
//     // 'testB': { uuid: '604e5cc556a62d2e', x: 541, y: 21, id: 'testB' },
//     'testA': { uuid: '604e5cc556a62d2e', x: 524, y: 468, id: 'testA' },
//     'testB': { uuid: '604e5cc556a62d2e', x: 1025, y: 300, id: 'testB' }
// }


export default class Widget3DView extends React.Component {
    state: WidgetState = {
        showControlTip: false,
        options: {
            showBuilding: { type: 'checkbox', default: true, value: true },
            showWorld: { type: 'checkbox', default: false, value: false },
            textures: { type: 'checkbox', default: false, value: false },
            floor: { type: 'checkbox', default: false, value: false },
            ambientOcclusion: { type: 'checkbox', default: false, value: false },
            showDebug: { type: 'checkbox', default: false, value: false },
            showPersonTags: { type: 'checkbox', default: false, value: false },
            buildingFloorColour: { type: 'color', default: 'rgba(125,125,125,0.75)', value: 'rgba(125,125,125,0.75)' },
            background: { type: 'color', default: 'rgba(255,255,255,1)', value: 'rgba(255,255,255,1)' },
            personColour: { type: 'color', default: '#ff6000', value: '#ff6000' }
            // showOrigin: { type: 'checkbox', default: false }
        },
        scenedata: {
            people: {}
        },
        scene: {
            irlynx_604e5cc556a62d2e: { uuid: '604e5cc556a62d2e', people: {} },
            irlynx_r26igl66kj3y9ew6: { uuid: 'r26igl66kj3y9ew6', people: {} },
            irlynx_vodafoneproto: { uuid: 'vodafoneproto', people: {} },
            irlynx_uzavjewnvc4bg4tr: { uuid: 'uzavjewnvc4bg4tr', people: {} } // floor 1
        },
        showFloor1: true,
        showFloor3: true,
        showFloor6: true,
        frames: []
    }

    sceneClearInterval: any;

    componentDidMount() {
        api.on('irlynx', this.processNewData)

        // disabled for now... too much data at once        
        // fetch('/api/irlynx/list')
        //     .then(res => res.json())
        //     .then((json: IRLynxDBRaw[]) => {

        //         // console.log(json);

        //         const ir = new IoT_IRLynx();
        //         let frames = []
        //         for (var f of json) {
        //             if (f.frame.D) {
        //                 frames = frames.concat(ir.decodeFrame(f.frame));
        //                 this.processNewData(f)
        //             }
        //         }
        //         this.setState({ frames })
        //         // console.log(json);
        //     })
        this.sceneClearInterval = setInterval(() => {
            // let scenedata = this.state.scenedata;
            // scenedata.people = {}
            // this.setState({ scenedata })
        }, 30000)
    }

    componentWillUnmount() {
        api.removeListener('irlynx', this.processNewData);
        clearInterval(this.sceneClearInterval);
    }


    processNewData = (f: any) => {

        let frames = this.state.frames;

        const ir = new IoT_IRLynx();
        const latestDecodedFrames: any = ir.decodeFrame(f.frame)


        let scene = this.state.scene;
        const sceneObjName = 'irlynx_' + f.uuid;
        if (scene[sceneObjName] === undefined) scene[sceneObjName] = {
            uuid: f.uuid,
            peopleNumber: 0,
            people: {},
            counters: {}
        };

        let scenedata: any = { people: {} } // this.state.scenedata;

        if (!latestDecodedFrames) {
            throw new Error('missing latest Decoded Frames');
            return;
        }

        for (var fr of latestDecodedFrames) {

            if (fr.table) {


                scene[sceneObjName].counters[fr.table + '_' + fr.res] = fr.values[0][1]
            }

            if (fr.function === 'eventPeopleNumber') {
                scene[sceneObjName].peopleNumber = fr.peopleNumber;

                if (Object.keys(scene[sceneObjName].people).length > fr.peopleNumber) {
                    //clear
                    scene[sceneObjName].people = {};
                }

                scene[sceneObjName].peopleNumber = Object.keys(scene[sceneObjName].people).length;
            }

            if (fr.function === 'eventDisappear') {
                delete scene[sceneObjName].people[fr.id]
            }

            if (fr.function === 'eventLocation') {
                let eventLocation: IRLynxEventLocation = fr;

                scenedata.people[f.uuid + '_' + eventLocation.id.toString()] = {
                    uuid: f.uuid,
                    id: eventLocation.id.toString(),
                    x: eventLocation.x,
                    y: eventLocation.y
                }

                if (scene[sceneObjName].people === undefined) scene[sceneObjName].people = {};

                var newpersondata = {
                    id: eventLocation.id.toString(),
                    x: eventLocation.x,
                    y: eventLocation.y,
                    idle: 0, //default
                    lastUpdated: new Date()
                }

                // check if person already exists
                if (scene[sceneObjName].people[eventLocation.id.toString()]) {
                    // check if the location has not changed...
                    let lastperson = scene[sceneObjName].people[eventLocation.id.toString()];
                    if ((lastperson.x === newpersondata.x) && (lastperson.y === newpersondata.y)) {
                        // has not moved!
                        newpersondata.idle = lastperson.idle + 1;
                    } else {
                        newpersondata.idle = 0; //resets to 0 if moved.
                    }
                }

                scene[sceneObjName].people[eventLocation.id.toString()] = newpersondata;

                scene[sceneObjName].peopleNumber = Object.keys(scene[sceneObjName].people).length;
            }
        }

        // remove static persons
        // for (var person of scene[sceneObjName]) {

        // }

        frames = frames.concat(latestDecodedFrames);
        this.setState({ frames, scenedata, scene })
    }

    changeOption(optionChange: WidgetOption) {

        try {
            let options = this.state.options;
            let propname = Object.keys(optionChange)[0];
            // @ts-ignore
            options[propname].value = optionChange[propname]
            this.setState({ options });
        }
        catch (err) { console.error(err); }
    }

    render() {
        return <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>

            <View3DUserOptions
                options={this.state.options}
                data={this.state}
                propsAction={(e: any) => {
                    if (e.option) { this.changeOption(e.option); }
                }}
                onOpen={() => {
                    this.setState({ showControlTip: false })
                }}
            />



            {(this.state.showControlTip) && <div onClick={() => { this.setState({ showControlTip: false }) }}
                style={{
                    display: "flex", flexDirection: "row", position: 'absolute', zIndex: 3500, right: 25, bottom: 25, minHeight: 5,
                    border: "1px solid rgba(205,205,205)"
                }}>
                <div style={{ flex: 1, padding: 10, background: 'rgba(210,210,210,0.95)', opacity: 0.5 }}>CONTROLS: <strong>rotate:</strong> left mouse &nbsp;&nbsp;&nbsp;<strong>zoom:</strong> middle mouse &nbsp;&nbsp;&nbsp;<strong>move:</strong> right mouse</div>
                <div>
                    <Button
                        onClick={() => { this.setState({ showControlTip: false }) }}

                        sx={{ background: "rgba(205,205,205)", padding: "5px 8px" }}>
                        close
                    </Button>
                </div>
            </div>}

            {(this.state.options.showDebug.value) &&
                <Paper sx={{
                    minWidth: 400,
                    padding: 1,
                    pt:0,
                    overflow: 'auto',
                    zIndex: 100
                }}>
                    <pre style={{ whiteSpace: 'break-spaces', color: "black", fontSize: 10, padding:0  }}>{JSON.stringify(this.state.scene, null, 2)}</pre>
                </Paper>
            }

            <div style={{ flex: 1}}>
                <SceneDisplay background={this.state.options.background.value}>

                    {/* <GLOrigin /> */}
                    {/* <GLGrid /> */}

                    <Html position={[-10, -50, 0 + 6]} >
                        <Button
                            style={{
                                background: 'red', color: 'white',
                                whiteSpace: 'nowrap',
                                zIndex: 10
                            }}
                            onClick={() => { this.setState({ showFloor3: !this.state.showFloor3 }); }}>
                            floor 3
                        </Button>
                    </Html>
                    {(this.state.showFloor3) && <>
                        <Model url={{
                            path: '/blend_floor/',
                            obj: 'paddington_floor_3d.obj',
                            mtl: 'paddington_floor_3d.mtl'
                        }}
                            meshProps={{ position: [0, 0, 0] }}
                            materialProps={{
                                color: new Kolor(this.state.options.buildingFloorColour.value).noAlpha(),
                                depthWrite: true,
                                depthTest: true,
                                transparent: true,
                                opacity: new Kolor(this.state.options.buildingFloorColour.value).alpha()
                            }}
                        />

                        {(this.state.scene['irlynx_r26igl66kj3y9ew6']) &&
                            <GLIRLynxPeople
                                color={this.state.options.personColour.value}
                                hideTags={!this.state.options.showPersonTags.value}
                                hideGizmo
                                showDebug={this.state.options.showDebug.value}
                                cornerA={[-0.9316, -73.5069, 0]}
                                cornerB={[119.0831, 0.6189, 0]}
                                position={[0, 0, 0]}
                                sceneObject={this.state.scene['irlynx_r26igl66kj3y9ew6']}
                                people={this.state.scene['irlynx_r26igl66kj3y9ew6'].people}
                                dataScale={{ w: 1350, h: 822 }}
                            />}
                    </>}


                    <Html position={[-10, -50, 18 + 6]} >
                        <Button
                            style={{ background: 'red', color: 'white', whiteSpace: 'nowrap' }}
                            // spot={this.state.showFloor6}
                            // text='floor 6'
                            onClick={() => { this.setState({ showFloor6: !this.state.showFloor6 }); }}>
                            floor 6
                        </Button>
                    </Html>
                    {(this.state.showFloor6) && <>
                        <Model url={{
                            path: '/blend_floor/',
                            obj: 'paddington_floor_6.obj',
                            mtl: 'paddington_floor_6.mtl'
                        }}
                            meshProps={{ position: [0, 0, 18] }}
                            materialProps={{
                                color: new Kolor(this.state.options.buildingFloorColour.value).noAlpha(),
                                depthWrite: true,
                                depthTest: true,
                                transparent: true,
                                opacity: new Kolor(this.state.options.buildingFloorColour.value).alpha()
                            }}
                        />

                        {(this.state.scene['irlynx_604e5cc556a62d2e']) &&
                            <GLIRLynxPeople
                                color={this.state.options.personColour.value}
                                hideTags={!this.state.options.showPersonTags.value}
                                hideGizmo
                                showDebug={this.state.options.showDebug.value}
                                flipXY
                                position={[0, 0, 0]}
                                cornerA={[81.0532, -20.7147, 18]}
                                cornerB={[111.9758, -63.4234, 18]}
                                sceneObject={this.state.scene['irlynx_604e5cc556a62d2e']}
                                people={this.state.scene['irlynx_604e5cc556a62d2e'].people}
                                dataScale={{ w: 400, h: 600 }}
                            />}
                    </>}

                    {/* FLOOR 1 */}
                    <Html position={[-10, -50, -18 + 6]} >
                        <Button
                            style={{ background: 'red', color: 'white', whiteSpace: 'nowrap' }}
                            // spot={this.state.showFloor1}

                            onClick={() => { this.setState({ showFloor1: !this.state.showFloor1 }); }} >
                            floor 1
                        </Button>
                    </Html>

                    {(this.state.showFloor1) && <>
                        <Model url={{
                            path: '/blend_floor/',
                            obj: 'paddington_floor_1.obj',
                            mtl: 'paddington_floor_1.mtl'
                        }}
                            meshProps={{ position: [0, 0, -18] }}
                            materialProps={{
                                color: new Kolor(this.state.options.buildingFloorColour.value).noAlpha(),
                                depthWrite: true,
                                depthTest: true,
                                transparent: true,
                                opacity: new Kolor(this.state.options.buildingFloorColour.value).alpha()
                            }}
                        />

                        {(this.state.scene['irlynx_uzavjewnvc4bg4tr']) &&
                            <GLIRLynxPeople
                                color={this.state.options.personColour.value}
                                hideTags={!this.state.options.showPersonTags.value}
                                hideGizmo={!this.state.options.showDebug.value}
                                showDebug={this.state.options.showDebug.value}
                                // flipXY
                                position={[0, 0, 0]}
                                cornerA={[68.91, -30.24, -18]}
                                cornerB={[91.77, -2.83, -18]}
                                sceneObject={this.state.scene['irlynx_uzavjewnvc4bg4tr']}
                                people={
                                    this.state.scene['irlynx_uzavjewnvc4bg4tr'].people
                                    // // Calibation
                                    // {
                                    //     "0": { id: "0", x: 35, y: 35, idle: 1, lastUpdated: new Date().toISOString() },
                                    //     "1": { id: "1", x: 135, y: 135, idle: 1, lastUpdated: new Date().toISOString() },
                                    //     // "2": { id: "2", x: 34, y: 95, idle: 1, lastUpdated: new Date().toISOString() },
                                    //     // "3": { id: "3", x: 80, y: 41, idle: 1, lastUpdated: new Date().toISOString() },
                                    // }
                                }
                                dataScale={{ w: 175, h: 175 }}
                            />}
                    </>}

                    {/* END FLOOR 1 */}

                    {(this.state.options.floor.value) && <mesh castShadow receiveShadow
                        rotation={[0, 0, 0]}
                        position={[0, 0, -50]}>
                        <planeGeometry
                            attach='geometry'
                            args={[50000, 50000]} />
                        <meshPhysicalMaterial attach='material'
                            depthWrite={true}
                            depthTest={true}
                            color={new Color('#333')} />
                    </mesh>}

                    {(this.state.options.showWorld.value) && <Suspense fallback={null}>
                        <Model url={{
                            path: '/gmap_paddington/',
                            obj: 'paddington_world.obj',
                            mtl: 'paddington_world.mtl'
                        }} meshProps={{ position: [0, 0, 80] }}
                            materialProps={
                                (this.state.options.textures.value) ? {
                                    color: undefined,
                                    transparent: false,
                                    opacity: 1
                                } : {
                                    color: '#333',
                                    transparent: false,
                                    opacity: 1
                                }}
                        /></Suspense>}

                    {(this.state.options.showBuilding.value) && <Suspense fallback={null}>
                        <Model url={{
                            path: '/gmap_paddington/',
                            obj: 'paddington_building.obj',
                            mtl: 'paddington_building.mtl'
                        }}
                            meshProps={{ position: [0, 0, 80], castShadow: true }}
                            materialProps={(this.state.options.textures.value) ? {
                                color: undefined,
                                transparent: true,
                                opacity: .5
                            } : {
                                depthWrite: true,
                                depthTest: true,
                                color: '#000',
                                transparent: true,
                                opacity: 0.05
                            }} /></Suspense>}


                </SceneDisplay>
            </div>


        </div >

    }


};

function Box(props: any) {
    // This reference will give us direct access to the mesh
    const mesh: any = useRef()

    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
            onClick={() => setActive(!active)}
            onPointerOver={() => setHover(true)}
            onPointerOut={() => setHover(false)}>
            <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
            <meshStandardMaterial attach="material" color={hovered ? 'hotpink' : 'orange'} />
        </mesh>
    )
}



function SceneDisplay(props: any) {
    const [scene, setScene] = useState(sceneData);
    const orbit = useRef()



    return <div style={{ background: 'rgb(0,0,0)', height: '100%', zIndex: 10 }} ><Canvas
        style={{ background: props.background, height: '100%' }}
        // colorManagement
        // shadowMap
        camera={{
            near: 1,
            far: 10000,
            // position: [-82, 48, 33] 
            position: [50, -150, 50],
            up: [0, 0, 1]
        }}
        onCreated={({ gl }) => {
            gl.shadowMap.enabled = true
            gl.shadowMap.type = THREE.PCFSoftShadowMap
        }}>
        <CameraControls target={new Vector3(62, -31, 1)} ref={orbit} />

        <ambientLight color={new Color('#cccccc')} intensity={1} />
        {/* <hemisphereLight position={[0, 0, 150]} args={[new Color('#eee'), 0x000000, 0.9]} /> */}

        <pointLight
            color={new Color('#efe6d6')}
            intensity={.5}
            position={[200, 50, 350]}
            shadow-bias={-0.00005}
            shadowCameraFar={2000}
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            castShadow={true}
        />

        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        {/* <Plane /> */}
        {/* <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} /> */}
        <RecursiveScene data={scene.scene} onSelect={(entry: any) => {
            var datascene = clone(scene.scene);
            function updateEntry(datainput: any) {
                var datain = clone(datainput);
                for (var e in datain) {
                    if (datain[e].unique === entry.unique) {
                        datain[e] = entry;
                    } else {
                        datain[e].selected = false;
                    }

                    if (datain[e].nested) {
                        datain[e].nested = updateEntry(datain[e].nested);
                    }
                }
                return datain;
            }
            datascene = updateEntry(datascene);
            setScene({ scene: datascene })
        }} />

        {props.children}

    </Canvas></div>

}

export function RecursiveScene(props: any) {
    return <>
        {props.data.map((entry: any) => {

            if (entry.type === 'plane') {
                return <Plane
                    onSelect={() => {
                        let entryUpdate = clone(entry);
                        entryUpdate.selected = true;
                        props.onSelect(entryUpdate)
                    }}
                    key={entry.unique}
                    castShadow
                    receiveShadow
                    {...entry}
                />
            }

            if (entry.type === 'collection') {
                if (entry.nested) {
                    return <RecursiveScene key={entry.unique} data={entry.nested} onSelect={(entry: any) => {
                        props.onSelect(entry);
                    }} />
                }
                return <Box key={entry.unique} position={[1.2, 0, 0]} />
            }

            return null

        })}
    </>
}



export function OptionsPanel() {
    let [show, setShow] = useState(false);
    return <div>
        <Button
            // icon='fas fa-wrench'
            // startIcon TODO
            onClick={() => { setShow(!show) }} >
            options
        </Button>
        {(show) && <div style={{ background: 'rgba(0,0,0,0.75)', padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div><Checkbox checked={true} onChange={() => { }} /></div>
                <div>World</div>
            </div>
        </div>}
    </div>
}




export class Kolor {
    rgb: any = [0, 0, 0, 0];

    constructor(color: string) {
        var cache,
            p = parseInt, // Use p as a byte saving reference to parseInt
            color = color.replace(/\s/g, ''); // Remove all spaces

        // Checks for 6 digit hex and converts string to integer
        if (cache = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.exec(color))
            cache = [p(cache[1], 16), p(cache[2], 16), p(cache[3], 16)];

        // Checks for 3 digit hex and converts string to integer
        else if (cache = /#([\da-fA-F])([\da-fA-F])([\da-fA-F])/.exec(color))
            cache = [p(cache[1], 16) * 17, p(cache[2], 16) * 17, p(cache[3], 16) * 17];

        // Checks for rgba and converts string to
        // integer/float using unary + operator to save bytes
        else if (cache = /rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color))
            cache = [+cache[1], +cache[2], +cache[3], +cache[4]];

        // Checks for rgb and converts string to
        // integer/float using unary + operator to save bytes
        else if (cache = /rgb\(([\d]+),([\d]+),([\d]+)\)/.exec(color))
            cache = [+cache[1], +cache[2], +cache[3]];

        // Otherwise throw an exception to make debugging easier
        else throw color + ' is not supported by $.parseColor';

        // Performs RGBA conversion by default
        isNaN(cache[3]) && (cache[3] = 1);

        // Adds or removes 4th value based on rgba support
        // Support is flipped twice to prevent erros if
        // it's not defined
        this.rgb = cache;
    }

    noAlpha() {
        return `rgb(${this.rgb[0]}, ${this.rgb[1]}, ${this.rgb[2]})`;
    }

    alpha() {
        let a = this.rgb[3];
        if (!a) return 1;
        return a;
    }

    componentToHex(c: number) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    rgbToHex() {
        return "#" + this.componentToHex(this.rgb[0]) + this.componentToHex(this.rgb[1]) + this.componentToHex(this.rgb[2]);
    }

}