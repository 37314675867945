import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

import { defaults3d } from './defaults'
import { useState, useMemo } from 'react'
import React from 'react'
import * as THREE from 'three';
import { Color, Material, MaterialParameters, MeshPhysicalMaterialParameters } from 'three';

interface ModelProps {
    url: {
        path: string
        obj: string
        mtl: string
    },
    meshProps?: any
    materialProps?: any
}

// export function Model({ url, meshProps = {}, materialProps }: ModelProps) {
export function Model(props: ModelProps) {

    let { url, meshProps, materialProps }: ModelProps = props;

    const [obj, set]: any = useState()
    useMemo(() => {
        let mtlLoader = new MTLLoader();
        mtlLoader.setPath(url.path)
        return mtlLoader.load(url.mtl, (materialCreator: any) => {
            materialCreator.preload();

            if (materialProps) {

                Object.keys(materialCreator.materials).map((key) => {
                    // if (materialCreator.materials[key].map !== null) 

                    try {
                        materialCreator.materials[key].map.encoding = THREE.sRGBEncoding;
                    } catch (e) { }

                    let matSettings: MeshPhysicalMaterialParameters = {
                        ...{
                            reflectivity: 1,
                            // roughness: 1,
                            roughnessMap: materialCreator.materials[key].map,
                            map: materialCreator.materials[key].map,
                            bumpScale: -0.25,
                            bumpMap: materialCreator.materials[key].map,
                            toneMapped: true,
                            transparent: true,
                        }, ...materialProps
                    }

                    // try {
                    //     matSettings.map = materialCreator.materials[key].map
                    // } catch (e) { }

                    if (materialProps) {
                        if (materialProps.color) {
                            matSettings.map = undefined;
                        }
                    }

                    materialCreator.materials[key] = new THREE.MeshPhysicalMaterial(matSettings)


                })
            }


            var objLoader = new OBJLoader();
            objLoader.setMaterials(materialCreator);
            objLoader.setPath(url.path);
            objLoader.load(url.obj, set);
        })

        // return new OBJLoader().load(url, set)
    }, [JSON.stringify(props)])

    // if (obj) {
    //     let Group: any = obj;
    //     console.log(obj);
    //     return <mesh></mesh>
    // } else {
    //     return null
    // }

    if (obj) {
        return <>
            {obj.children.map((child: any, i: any) => {


                // let map = undefined
                var material: any = new THREE.MeshPhysicalMaterial({ color: '#cccccc' }) //default
                if (child.material) {
                    material = child.material
                }

                if (materialProps) {
                    // material = new THREE.MeshPhysicalMaterial(materialProps)
                    // material = { ...child.material, ...materialProps }

                    for (var key of Object.keys(materialProps)) {
                        if (key === 'color') {
                            material.color = new Color(materialProps.color)
                        } else {
                            material[key] = materialProps[key];
                        }
                    }

                    // child.material.transparent = true;
                    // // child.material.opacity = materialProps;
                    // child.material.opacity = 0.25;
                }
                return <mesh
                    key={i}
                    position={child.position}
                    material={material}
                    geometry={child.geometry} {...defaults3d}
                    {...meshProps}
                >
                </mesh>
            })}
        </>
    } else {
        return null;
    }


}