import React, { useState, useMemo } from 'react';

import { Color, Vector3, Matrix4, DoubleSide } from 'three';
// import { Gizmo } from '../../view3d/glGizmo';

import { TextureLoader } from 'three'

export function Plane(props: any) {
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    let [position, setPosition] = useState(new Vector3().fromArray((props.position) ? props.position : [0, 0, 0]));
    let [] = useState(new Vector3().fromArray((props.rotation) ? props.rotation : [0, 0, 0]));
    let [scale, setScale] = useState(new Vector3().fromArray([1, 1, 1]));

    const [texture] = useMemo(() => {
        if (props.url) {
            const loader = new TextureLoader()
            return [loader.load(props.url)]
        } else return [undefined]
    }, [props.url])

    return <group >
        {/* {(props.selected === true) && <Gizmo
            hideOptions={false}
            showPosition
            position={position.toArray()}
            scale={scale.toArray()}
            uniqueName='pivot'
            onTransform={(matrixWorld: Matrix4) => {
                console.log(JSON.stringify(matrixWorld))
                let vect = new Vector3();
                vect.applyMatrix4(matrixWorld);
                setPosition(vect);

                let vectscale = new Vector3(1, 1, 1);
                vectscale.setFromMatrixScale(matrixWorld)
                setScale(vectscale);
            }} />} */}

        <group position={position}
            scale={scale}
            rotation={props.rotation}>

            {/* <GLLine vertices={[
                new Vector3(-5, -5),
                new Vector3(-5, 5),
                new Vector3(5, 5),
                new Vector3(5, -5),
                new Vector3(-5, -5)]}
                color={(props.selected) ? 'red' : 'white'}
            /> */}

            <mesh
                castShadow
                receiveShadow
                onClick={() => {
                    setActive(!active);
                    props.onSelect();
                }}
                onPointerOver={() => setHover(true)}
                onPointerOut={() => setHover(false)}
            >
                <planeGeometry
                    attach='geometry'
                    args={(props.size) ? props.size : [10, 10]} />
                <meshPhysicalMaterial
                    attach='material'
                    side={DoubleSide}
                    map={texture}
                    depthWrite={true}
                    depthTest={true}
                    opacity={(props.hovered) ? 0.75 : 1}
                    transparent={true}
                    color={hovered ? new Color('#555555') : new Color('#cccccc')} />
            </mesh>
        </group>

    </group>
}