import { Box as TempBox, BoxTypeMap, SxProps } from '@mui/material'

export const Box = (props: {
    component?: any
    sx?: SxProps,
    children?: any
    [index:string]: any
}) => {
    // @ts-ignore
    return <TempBox component="div" {...props} />
}
