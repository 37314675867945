import { Paper, Input, TextField, Typography } from '@mui/material';
import React, { Component } from 'react';
import { OptionMaster } from './optionmaster'
import { Box } from '../../box'
import { Kolor } from '../widgets/widget_view3D';



export default class OptionsColor extends OptionMaster {
  state = { val: '#FFFFFF' }

  constructor(props: any) {
    super(props);

    let value = '#ff0000' // default
    if (props) {
      if (props.option) {
        if (props.option.default) { value = props.option.default }
        if (props.option.val) { value = this.props.option.val }
      }
    }

    this.state.val = value;
  }

  onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.apply();
    }
  }

  onChange = (event: any) => {
    this.setState({ val: event.target.value }, () => {
      this.apply();
    });
  }

  render() {
    return <Box sx={{ mt: 1, mb: 2 }}>
      <Typography sx={{ fontSize: '0.75em' }}>{this.props.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          type="color"
          sx={{ width: 30, pr: 1 }}
          value={new Kolor(this.state.val).rgbToHex()}
          onChange={this.onChange}
        />
        <Input value={this.state.val} onChange={this.onChange} fullWidth />
      </Box>
    </Box>
  }
}