import * as React from "react";
import { api } from './api'
import { themeMUILight } from "./theme";
import { DashboardState } from './components/dashboard/interfaces';
import Widget3DView from './components/dashboard/widgets/widget_view3D';

import { ThemeProvider, CssBaseline } from "@mui/material";
import { AuthWrap } from "./auth/AuthService";

interface Props { }
interface State {
    counter: number,
    message: string,
    inputmessage: string,
    dashboardState?: DashboardState,
    height: number
}

export class App extends React.Component<Props, State> {
    state: State = {
        counter: 0,
        message: '',
        inputmessage: '',
        dashboardState: undefined,
        height: 100
    }

    el: any;

    constructor(props: any) {
        super(props)
        window.addEventListener('resize', this.updateHeight)
        this.updateHeight();
    }

    updateHeight = () => {
        if (this.state.height != window.innerHeight) this.setState({ height: window.innerHeight });
    }

    componentDidMount() {
        // api.test();

        api.on('socket', (data) => {
            console.log(data);
            this.setState({ message: data })
        })

    }



    render() {
        return <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
            <ThemeProvider theme={themeMUILight}>
                <CssBaseline />
                <AuthWrap
                    authority="https://vodafone001.commander.io/auth"
                    client_id="ps.vodafone.paddington.spa.prod"
                >
                    <Widget3DView />
                </AuthWrap>

            </ThemeProvider>
        </div>
    }
}
