import React, { Component } from 'react';
import { OptionMaster } from './optionmaster'

import { Button } from '@mui/material';



export default class OptionsNumber extends OptionMaster {
  state = { val: 20 }

  constructor(props: any) {
    super(props);

    let value = 20 // default
    if (props) {
      if (props.option) {
        if (props.option.default !== undefined) { value = props.option.default }
        if (props.option.val !== undefined) { value = this.props.option.val }
      }
    }

    this.state.val = parseFloat(value.toString());
  }

  onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.apply();
    }
  }

  onChange = (event: any) => {
    this.setState({ val: parseFloat(event.target.value) }, () => {
      this.apply();
    });
  }

  addSubtract = (amount: number) => {
    this.setState({ val: parseFloat(this.state.val.toString()) + amount }, () => {
      this.apply();
    });
  }



  render() {
    return (<>
      {this.props.name}:<br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        todo input number
      </div>
    </>)
  }
}