
import React, { useLayoutEffect, useRef } from "react";


export function GLLine(props: any) {

    const ref: any = useRef()

    useLayoutEffect(() => {
        if (!ref) return;
        if (!ref.current) return;

        if (!props.vertices) return;

        ref.current.geometry.setFromPoints(props.vertices)
    }, [props.vertices])

    return <group>
        <line ref={ref}>

            <bufferGeometry attach='geometry'
            // vertices={props.vertices}
            // onUpdate={self => (self.verticesNeedUpdate = true)}
            />
            <lineBasicMaterial
                // depthTest={false}
                // depthWrite={false}
                transparent
                linewidth={1}
                depthWrite={true}
                depthTest={true}
                fog={false}
                toneMapped={false}
                {...props} />
        </line>
    </group>
}
