import { createTheme } from "@mui/material";

export const themeMUILight = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: "#ff0000",
    },
    secondary: {
      main: "#0de2d0",
      contrastText: "rgba(0,0,0,0.75)",
    },
    background: {
      default: '#fff',
      paper: 'rgba(220,220,220,0.95)',
    },
    // text: {
    //   primary: "#ffffff",
    // },
  },
});