// Rouan van der Ende 2022 - React MUI Auth Wrapper 2.2.0 no navbar

import { LinearProgress } from '@mui/material';
import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import React from 'react';

export interface IAuthWrapState {
  user?: User
  menuOpen: boolean
  menuAnchor?: any
}

export interface IAuthWrapProps {
  /** example: "https://vodacomnot.commander.io/auth" */
  authority: string
  /** example: "ps.vodacom.netcoolalarmui.spa.prod" */
  client_id: string
  children: any
}

export class AuthWrap extends React.Component<IAuthWrapProps, IAuthWrapState> {
  state: IAuthWrapState = {
    menuOpen: false
  }

  private _userManager = new UserManager({
    authority: this.props.authority,
    client_id: this.props.client_id,
    redirect_uri: `${window.location.origin}/`,
    silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
    post_logout_redirect_uri: `${window.location.origin}`,
    response_type: 'id_token token',
    scope: 'openid profile email api',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  })

  componentDidMount = () => {
    this.initAuth();
  }

  initAuth = async () => {
    if (window.location.hash.includes("id_token")) {
      try { await this._userManager.signinRedirectCallback(); }
      catch (e) { console.error(e); }
      finally {
        // window.location.replace('/')
      }
    };

    const user = await this._userManager.getUser()

    console.log(user);

    if (user !== null && (user.expires_in < 0)) {
      console.log('Token expired, renewing.')
      await this._userManager.signinRedirect(); // auto login
      return;
    }

    if (user === null) {
      console.log('User not logged in.')
      await this._userManager.signinRedirect(); // auto login
      return;
    }

    if (user) this.setState({ user });
  }

  render = () => {
    if (!this.state.user) return <LinearProgress />

    return <>{this.props.children}</>
  }
}