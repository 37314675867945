export interface CloudAppResponse<T> {
    isSuccessful: boolean;
    message: string;
    data?: T;
    exceptionMessage?: string;
    status?: number;
}


export function encodeQueryData(data: any) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}


export function generateDifficult(count: number) {
    const _sym = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
    let str = '';
    for (let i = 0; i < count; i++) {
        const tmp = _sym[Math.round(Math.random() * (_sym.length - 1))];
        str += '' + tmp;
    }
    return str;
}

export function clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}


export function isJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


export function recursiveFlatToArray(inObj: any) {
    const res: any = [];

    (function recurse(obj, current?: any) {

        if (Array.isArray(obj)) { return; }

        for (const key in obj) {
            if (key) {
                const value = obj[key];
                const newKey = current ? current + '.' + key : key; // joined key with dot
                if (value && typeof value === 'object') {
                    res.push(newKey);
                    recurse(value, newKey); // it's a nested object, so do it again
                } else {
                    res.push(newKey); // it's not an object, so set the property
                }
            }
        }
    })(inObj);

    return res;
}

export function recursiveFlatToObject(inObj: any) {

    function dotNotate(obj: any, target: any = {}, prefix: string = "") {


        Object.keys(obj).forEach(function (key) {
            if (typeof (obj[key]) === "object") {

                if ((obj[key]) instanceof Date) { return target[prefix + key] = obj[key]; }

                dotNotate(obj[key], target, prefix + key + ".");
            } else {
                return target[prefix + key] = obj[key];
            }
        });

        return target;
    }



    return dotNotate(inObj, {}, '');
}