import { Person3D } from "./person3d"
import React, { useState } from "react"
// import { Gizmo } from "./glGizmo"
import { Matrix4, Vector3, MeshStandardMaterial, DoubleSide, Float32BufferAttribute } from "three";
import { Html } from "@react-three/drei";
import { GLLine } from "./glLine";

export interface GLIRLynxPeopleProps {
    color: any
    hideTags?: boolean
    hideGizmo?: boolean
    showDebug?: boolean
    position: [number, number, number]
    cornerA: [number, number, number]
    cornerB: [number, number, number]
    sceneObject: any
    people: any
    dataScale: { w: number, h: number }
    flipXY?: boolean
}

export function GLIRLynxPeople(props: GLIRLynxPeopleProps) {
    let { sceneObject, people } = props;

    // if (sceneObject === undefined) { return null }

    let uuid = sceneObject.uuid;

    // let [cornerA, setCornerA] = useState(new Vector3(80.7935, -60.2919, 18));
    // let [cornerB, setCornerB] = useState(new Vector3(109.245, -23, 18));
    let [cornerA, setCornerA] = useState(new Vector3().fromArray(props.cornerA));
    let [cornerB, setCornerB] = useState(new Vector3().fromArray(props.cornerB));

    return <group  {...props} >
        {/* {(props.hideGizmo !== true) && <Gizmo
            hideOptions
            showPosition
            position={cornerA.toArray()}
            uniqueName='cornerA'
            onTransform={(matrixWorld: Matrix4) => {
                let vect = new Vector3();
                vect.applyMatrix4(matrixWorld);
                setCornerA(vect);
                setCornerB(cornerB.clone().setZ(vect.z))
                // console.log('gizmo A', JSON.stringify(data));
            }} />}

        {(props.hideGizmo !== true) && <Gizmo
            hideOptions
            showPosition
            position={cornerB.toArray()}
            uniqueName='cornerB'
            onTransform={(matrixWorld: Matrix4) => {
                let vect = new Vector3();
                vect.applyMatrix4(matrixWorld);
                setCornerB(vect);
                setCornerA(cornerA.clone().setZ(vect.z))
                // console.log('gizmo A', JSON.stringify(data));
            }} />} */}

        {(props.showDebug) && <>
            <GLLine vertices={[
                cornerA,
                cornerA.clone().setY(cornerB.y),
                cornerB,
                cornerB.clone().setY(cornerA.y),
                cornerA]}
                color={(props.hideGizmo !== true) ? 'red' : 'white'}

            />

            <group position={cornerA.clone().lerp(cornerB, 0.5).setZ(cornerA.z + 0.1)}>
                {(props.hideGizmo !== true) && <Html>
                    <span style={{ color: 'rgba(45,45,45,0.5)', textAlign: 'center' }}>uuid:{uuid}</span>
                </Html>}
                <mesh >
                    <planeGeometry
                        attach="geometry"
                        args={[cornerB.x - cornerA.x, cornerB.y - cornerA.y]}

                    />
                    <meshStandardMaterial
                        transparent
                        depthWrite={false}
                        depthTest={false}
                        side={DoubleSide}
                        opacity={0.25}
                        attach="material"
                        color={(props.hideGizmo !== true) ? 'rgba(200,50,50)' : 'rgba(200,25,25,0.5)'} />
                </mesh>
            </group></>}
        {
            Object.keys(people).map((id, i) => {
                // floor 6 people

                // disable filtering
                // if (people[id].idle > 1) return null;
                // if (new Date().getTime() - people[id].lastUpdated.getTime() > 7500) return null;

                if (props.flipXY) {
                    return <Person3D
                        key={id}
                        hideTags={props.hideTags}
                        color={props.color}
                        person={people[id]}
                        position={
                            [cornerB.x - (1 - (people[id].y / props.dataScale.w)) * (cornerB.x - cornerA.x),
                            cornerB.y - (people[id].x / props.dataScale.h) * (cornerB.y - cornerA.y),
                            cornerA.z]}
                    />
                } else {
                    return <Person3D
                        key={id}
                        hideTags={props.hideTags}
                        color={props.color}
                        person={people[id]}
                        position={
                            [cornerB.x - (1 - (people[id].x / props.dataScale.w)) * (cornerB.x - cornerA.x),
                            cornerB.y - (people[id].y / props.dataScale.h) * (cornerB.y - cornerA.y),
                            cornerA.z]}
                    />
                }



            })
        }
    </group>
}